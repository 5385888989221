import "./LoadingScreen.css";

const LoadingScreen = () => {
    
      
    return(
    <div className="loading-body">
    <div className="loading-box">
    <div className="loading-container">
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
    </div>
    </div>
    </div>
    );
    
}
export default LoadingScreen;