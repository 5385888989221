import { useContext, useState } from "react";
import { ReactComponent as CloseMenu } from "../../assets/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import Logo from '../../assets/torii-gate.png';
import "./Header.css";
import { NavLink } from 'react-router-dom';
import SignInWithGoogle from "../SignInWithGoogle/SignInWithGoogle";
import { isLoggedIn, userData } from "../../Contexts";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const {loginStatus} = useContext(isLoggedIn);
  const {user} = useContext(userData);

  return (
    <div className="header">
      <div className="logo-nav">
        <div className="logo-container">
          <NavLink to="/"><img src={Logo} alt="logo"/>Discover Japan</NavLink>
        </div>

        <ul className={click ? "nav-options active" : "nav-options"}>
        {loginStatus === true ? <li className="option" onClick={closeMobileMenu}>
            <NavLink to="/my-trip">{user?.givenName + " 's " || "My "}Trip</NavLink>
          </li> : ''}
          {loginStatus === false ? <li className="option" onClick={closeMobileMenu}>
            <NavLink to="/">Home</NavLink>
          </li> : ''}
          <li className="option" onClick={closeMobileMenu}>
            <NavLink to="/expeditions">Expeditions</NavLink>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <NavLink to="">Contact</NavLink>
          </li>
          <li className=" option mobile-option" onClick={closeMobileMenu}>
            <SignInWithGoogle />
          </li>
        </ul>
      </div>
      <ul className="signin">
        <li onClick={closeMobileMenu}>
          <SignInWithGoogle />
        </li>
      </ul>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
          
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  );
}

export default Header;