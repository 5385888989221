import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ColorThemeSwitch from "../../Components/ColorThemeSwitch/ColorThemeSwitch";
import { IJapanExpeditionData } from "../../types";
import { FaRoute } from "react-icons/fa";
import "./MyTrip.css";
import { djUserData, japanExpeditionsData, themeColor, userData } from "../../Contexts";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { getUser, removeExpedition } from "../../firestoreFunctions";
import { IoTrashSharp } from "react-icons/io5";

const MyTrip = () => {
    const {expeditions} = useContext(japanExpeditionsData);
    const {user} = useContext(userData);
    const {mode} = useContext(themeColor);
    const {djUser, setDjUser} = useContext(djUserData);
    const [savedExp, setSavedExp] = useState<IJapanExpeditionData[]>();

    useEffect(() => {
        document.title = `${user.givenName}'s Trip - Discover Japan`;
        let saved: IJapanExpeditionData[] = [];
        // eslint-disable-next-line array-callback-return
        // getUser(user.googleId!).then((res => setDjUser(res!)));
        if(typeof(djUser.expeditionIds) !== 'undefined'){
        // eslint-disable-next-line array-callback-return
        expeditions.filter((e) => {if(djUser.expeditionIds?.includes(e.id)){return e}}).map((e)=>{
            saved.push(e);
        })
        setSavedExp(saved);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[djUser]);
    
      const unsaveExp  = (expId: string) => {
        let tempSavedExp :IJapanExpeditionData[] = [];
        // eslint-disable-next-line array-callback-return
        savedExp?.filter((e) => {if(e.id !== expId){return true}}).map((e) => {tempSavedExp.push(e)});
        setSavedExp(tempSavedExp);
        removeExpedition(user.googleId!, expId!);
        getUser(user.googleId!).then((res => setDjUser(res!)));
      }
      
    return(
        <div>
        {typeof(djUser.expeditionIds) === 'undefined' ? <LoadingScreen/> : <div className={`mytrip-body ${mode === "dark" ? "mytrip-body-dark" : ''}`}>
            <ColorThemeSwitch/>
            <section className={`profile-info ${mode === "dark" ? "profile-info-dark" : ''}`}>
                <div className="picture-name-container">
                    <img src={user?.imageUrl || "https://i.imgur.com/6k6Iqba.png"} alt={user?.name || "no profile picture"}/>
                    <div className="name-info">
                        <h1>{user?.name || "Anonymous"}</h1>
                        <p>{user?.email || "No email"}&nbsp;</p>
                        <span className="traveler-tag">Joined in {new Date(djUser.registration!).getFullYear()}</span>
                    </div>
                </div>
            </section>
            <section>
                <div className="saved-expeditions-container">
                    {savedExp?.map((e) => {
                        return(
                        <div className={`saved-expeditions-item ${mode === 'dark' ? 'saved-expeditions-item-dark' : ''}`}>
                            <Link to={`expeditions/${e.id}`}>
                                <img src={e.pictures[0]} alt={e.name}/>
                            </Link>
                            <div>
                            <Link className={`saved-exp-link ${mode === 'dark' ? 'saved-exp-link-dark' : ''}`} to={`expeditions/${e.id}`}>{e.name}</Link>
                            <div className="saved-exp-btn-container">
                            <button className={`btn-openmaps ${mode === 'dark' ? 'btn-openmaps-dark' : ''}`}><a href={`https://www.google.nl/maps/dir//${e.coordinates}/`} target="_blank" rel="noreferrer"><FaRoute/> Open in Maps</a></button>
                            <button className={`btn-unsave-exp ${mode === 'dark' ? 'btn-openmaps-dark' : ''}`} onClick={()=>unsaveExp(e.id)}><IoTrashSharp/></button>
                            </div>
                            </div>
                        </div>
                    )})}
                    {savedExp?.length === 0 ? 
                    <div className={`saved-expeditions-item ${mode === 'dark' ? 'saved-expeditions-item-dark' : ''}`}>
                    <p>&nbsp;You haven't saved any expeditions yet</p>
                    </div>
                 : ''}
                </div>
            </section>
        </div>}
    </div>
    );
}
export default MyTrip;
