import { useContext } from "react";
import GoogleLogin, { GoogleLogout } from "react-google-login";
import { djUserData, isLoggedIn, themeColor, userData } from "../../Contexts";
import { addUser } from "../../firestoreFunctions";

const SignInWithGoogle = () => {
    const {mode, setMode} = useContext(themeColor);
    const {loginStatus, setLoginStatus} = useContext(isLoggedIn);
    const {setUser} = useContext(userData);
    const {djUser, setDjUser} = useContext(djUserData);

    const handleLogin = (res: any) => {
        setUser(res.profileObj);
        setLoginStatus(true);
        let tempDarkPref: boolean = mode === 'dark' ? true : false;
        addUser(res.profileObj.googleId, tempDarkPref).then((res => setDjUser(res!)));
        if(typeof(djUser.expeditionIds) !== 'undefined'){
        djUser.darkmode === true ? setMode('dark') : setMode('light'); 
        }
    };
    const handleLoginOnFailure = (res: any) => {
        setLoginStatus(false);
    };
    const handleLogout = () => {
        setLoginStatus(false);
        
      };
    return(
        <div>
            {loginStatus === false ? <GoogleLogin
            clientId={"516364462774-h3b9gnfs9dbd3m8h6kuqqt6nn02vuliu.apps.googleusercontent.com"}
            buttonText="Log in with Google"
            onSuccess={handleLogin}
            onFailure={handleLoginOnFailure}
            isSignedIn={true}
            cookiePolicy={'single_host_origin'}
            /> :
            <GoogleLogout
            clientId={"516364462774-h3b9gnfs9dbd3m8h6kuqqt6nn02vuliu.apps.googleusercontent.com"}
            buttonText="Logout"
            onLogoutSuccess={handleLogout}>
            </GoogleLogout>}
      </div>
    )
}

export default SignInWithGoogle;