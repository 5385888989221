import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import db from "./firebase";
import { IFirestoreUser, IJapanExpeditionData } from "./types";

export async function getExpeditions() {
    let exps: IJapanExpeditionData[] = [];
    const querySnapshot = await getDocs(collection(db, "expeditions"));
    querySnapshot.forEach((doc) => {
    exps.push(doc.data() as IJapanExpeditionData);
    });
    return exps;
}

export async function addUser(googleId: string, mode: boolean) {
    const docRef = doc(db, "users", googleId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {

        await setDoc(doc(db, "users", googleId), {
            id: googleId.toString(),
            expeditionIds: [],
            darkmode: mode, 
            registration: Date.now()
        });
    }
    return getUser(googleId);
}

export async function getUser(googleId: string) {
    const docRef = doc(db, "users", googleId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data() as IFirestoreUser;
    }
}

export async function addExpedition(googleId: string, expId: string) {
    const docRef = doc(db, "users", googleId);
    await updateDoc(docRef, {
        expeditionIds: arrayUnion(expId)
    });
}

export async function removeExpedition(googleId: string, expId: string) {
    const docRef = doc(db, "users", googleId);
    await updateDoc(docRef, {
        expeditionIds: arrayRemove(expId)
    });
}

export async function setDarkModePref(googleId: string, mode: boolean) {
    const docRef = doc(db, "users", googleId);

    await updateDoc(docRef, {
        darkmode: mode
    });
}
