import { IForecast } from "./types";

export const getForecast = async (coordinates: string):Promise<IForecast> => {
    var requestOptions = {
        method: 'GET',
      };
      let tempCoord: string[] = coordinates.split(',');
      return fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${tempCoord[0]}&lon=${tempCoord[1]}&exclude=minutely,hourly,alerts&appid=29ee6773dce0d02a6ec462a8527f7128&units=metric`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
}

export const getIcon = (x: string): string => {
    return `http://openweathermap.org/img/wn/${x}.png`;
  }