import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import './Home.css';
import { useContext, useEffect } from "react";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { japanExpeditionsData } from "../../Contexts";

const Home = () => {
    const {expeditions} = useContext(japanExpeditionsData);
    const randExpIdx = Math.floor(Math.random() * (expeditions.length));

    useEffect(() => {
        document.title = `Discover Japan`;
      },[]);
      
    return(
        <div>
            {typeof(expeditions[randExpIdx]) === 'undefined' ? <LoadingScreen/> : <div className="bannerBody">
                <img src={expeditions[randExpIdx].pictures[0]} alt={expeditions[randExpIdx]?.name}/>
                <div className="overlay">
                    <div className="call-to-action">
                        <h2>Discover Japan presents</h2>
                        <h1>{expeditions[randExpIdx]?.name}</h1>
                        <p>Starting at ${expeditions[randExpIdx]?.minPrice}</p>
                        <button>
                        <Link to="/expeditions" >Explore <IoArrowForwardCircleOutline className="arrow-icon"/></Link>
                        </button>
                    </div>
                </div>
            </div>}
        </div>
    );
    
}
export default Home;