import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBlquXFPwqBS8T7UcYZXF0jBBV7yXh1Y6M",
    authDomain: "discover-japan-4279e.firebaseapp.com",
    projectId: "discover-japan-4279e",
    storageBucket: "discover-japan-4279e.appspot.com",
    messagingSenderId: "449306580033",
    appId: "1:449306580033:web:22b6a9ca4857c27af4a2c0"
};
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;