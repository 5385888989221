import { useEffect, useState } from "react";
import { getForecast, getIcon } from "../../OpenWeatherApi";
import { ICoordinates, IForecast } from "../../types";
import "./Forecast.css"

interface coordinatesProp{
    coordinates: ICoordinates;
}
let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const Forecast = (coordinates: coordinatesProp) => {
    const [forecast, setForecast] = useState<IForecast>();

    useEffect(() => {
        getForecast(coordinates.coordinates.coordinates).then( res => setForecast(res));
      },[coordinates]);

      return(
        <div>
            <section className="forecast-today">
            <p className="temperature-now">{forecast?.current.temp.toFixed(1)} &#8451;</p>
            <p className="phrase-today">{forecast?.current.weather[0].description || "unavailable"}</p>
            </section>
            <section className="upcoming-days">
                {
                forecast?.daily.slice(-8, -3).map(e => {
                    let day = new Date(e.dt*1000).getDay();
                    return(
                    <div key={e.dt}>
                        <table>
                            <tbody>
                            <tr>
                                <td className="td-day"><b>{weekdays[day]}</b></td>
                                <td className="td-icon"><img src={getIcon(e.weather[0].icon)} alt={e.weather[0].description}/></td>
                                <td className="td-temp">&#8595; {e.temp.min.toFixed(1)}  &nbsp;-&nbsp; 	&#8593; <b>{e.temp.max.toFixed(1)} &#8451;</b></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}) || <p>Loading</p>}
            </section>
        </div>
    )
}

export default Forecast;