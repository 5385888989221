import "./Expedition.css";
import { IoCalendarSharp, IoCloudyNightSharp, IoHeartOutline, IoHeartSharp, IoPricetag } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Forecast from "../../Components/Forecast/Forecast";
import ColorThemeSwitch from "../../Components/ColorThemeSwitch/ColorThemeSwitch";
import { addExpedition, getUser, removeExpedition } from "../../firestoreFunctions";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { djUserData, isLoggedIn, japanExpeditionsData, themeColor, userData } from "../../Contexts";

const Expedition = () => {
    const {expeditions} = useContext(japanExpeditionsData);
    const [saveStatus, setSaveStatus] = useState<boolean>(false);
    const {loginStatus} = useContext(isLoggedIn);
    const {mode} = useContext(themeColor);
    const {user} = useContext(userData);
    const {djUser, setDjUser} = useContext(djUserData);
    
    let { id } = useParams<string>();
    let expedition = expeditions.find(
    (e) => (e.id) === id);   

    useEffect(() => {
        document.title = `${expedition?.name} - Discover Japan`;
        if(loginStatus === true){
            getUser(user.googleId!).then((res => setDjUser(res!)));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[expedition,loginStatus]);

    useEffect(() => {
        setSaveStatus(djUser.expeditionIds?.includes(expedition?.id!)!);
      },[djUser, expedition?.id]);

    let coordinates = expedition?.coordinates || "";

    const save = ()  => {
        addExpedition(user.googleId!, expedition?.id!);
        getUser(user.googleId!).then((res => setDjUser(res!)));
        setSaveStatus(true);
    }
    const unsave = ()  => {
        removeExpedition(user.googleId!, expedition?.id!);
        getUser(user.googleId!).then((res => setDjUser(res!)));
        setSaveStatus(false);
    }
    
    
    return(
        <div className={mode === 'dark' ? 'body-dark' : ''}>
            {typeof(expedition) === 'undefined' ? <LoadingScreen/> : <div>
            <ColorThemeSwitch/>
            <div className="title-banner">
                <img src={expedition?.pictures[0]} alt={expedition?.name}/>
                <div className="overlay">
                <h1>{expedition?.name}</h1>
                </div>
            </div>
            <div className="picture-detail-container">
                <div className="picture">
                {loginStatus === true ? saveStatus === true ? <button className="btn-save saved" onClick={unsave}><IoHeartSharp className="heart-icon"/> Saved</button>
                : <button className="btn-save" onClick={save}><IoHeartOutline className="heart-icon"/> Save</button> : ''}
                
                <img src={expedition?.pictures[1]} alt={expedition?.name}/>
                <div className="timeperiod">
                    <p><IoCalendarSharp/>&nbsp;&nbsp;{expedition?.timeperiod.start} to {expedition?.timeperiod.end}</p>
                </div>
                </div>
                <div className="detail-container">
                {expedition?.tourProviders.map((e)=>{
                    return(
                    <button className="book-at-guide"><a href={e.url} target="_blank" rel="noreferrer">Book at {e.name}</a></button>
                )})}
                <div className={`detail-item ${mode === 'dark' ? 'detail-item-dark' : ''}`}>
                    <p className="detail-item-title"><IoPricetag/>Minimum Price</p>
                    <p>${expedition?.minPrice} a person</p>
                </div>
                <div className={`detail-item ${mode === 'dark' ? 'detail-item-dark' : ''}`}>
                    <p className="detail-item-title"><IoCloudyNightSharp/> Weather</p>
                    <Forecast coordinates={{coordinates}}/>
                </div>
                </div>
                
            </div>
            </div>}
        </div>
    );
}
export default Expedition;