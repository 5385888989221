import "./Expeditions.css";
import { IoCalendarSharp, IoPricetag } from "react-icons/io5";
import { Link } from "react-router-dom";
import ColorThemeSwitch from "../../Components/ColorThemeSwitch/ColorThemeSwitch";
import { useContext, useEffect } from "react";
import { japanExpeditionsData, themeColor } from "../../Contexts";

const Expeditions = () => {
    const {expeditions} = useContext(japanExpeditionsData);
    const {mode} = useContext(themeColor);

    useEffect(() => {
        document.title = `Expeditions - Discover Japan`;
      },[]);

    return(
        <div className={`expeditions-body ${mode === 'dark' ? 'body-dark' : ''}`}>
            <h1 className={`expeditions-head ${mode === 'dark' ? 'exp-head-dark' : ''}`}>Our amazing expeditions</h1>
            <ColorThemeSwitch/>
            <div className="list">
            {expeditions.map((e) => {
                return(
                    <div key={e.id} className={mode === 'dark' ? 'list-item-dark' : 'list-item'}>
                        <Link to={e.id}>
                        <img src={e.pictures[0]} alt={e.name}/>
                        </Link>
                        <p className="item-details"><IoPricetag/> Starting at ${e.minPrice}&nbsp;&nbsp;&nbsp;<IoCalendarSharp/> {e.timeperiod.start}</p>
                        <div className="item-description">
                            <Link to={e.id} className={`item-title ${mode === 'dark' ? 'item-title-dark' : ''}`}>{e.name}</Link>
                        </div>
                    </div>
                )
            })}
            </div>
        </div>
    );
}
export default Expeditions;