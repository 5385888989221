import React, { useEffect, useState } from 'react';
import './Components/Header/Header';
import './App.css';
import Header from './Components/Header/Header';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from "react-router-dom";
import Home from './Pages/Home/Home';
import Expeditions from './Pages/Expeditions/Expeditions';
import MyTrip from './Pages/MyTrip/MyTrip';
import { IFirestoreUser, IJapanExpeditionData, IUser } from './types'; 
import Expedition from './Pages/Expedition/Expedition';
import { getExpeditions } from './firestoreFunctions';
import { djUserData, isLoggedIn, japanExpeditionsData, themeColor, userData } from './Contexts';
require('dotenv').config();

const App = () => {

  const [expeditions, setExpeditions] = useState<IJapanExpeditionData[]>([]);
  const [mode, setMode] = useState<string>('light');
  const [user, setUser] = useState<IUser>({});
  const [djUser, setDjUser] = useState<IFirestoreUser>({});
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  
  useEffect(() => {
    getExpeditions().then(res => setExpeditions(res));
  },[]);
  
  return (
    <div>
      <japanExpeditionsData.Provider value={{expeditions: expeditions, setExpeditions: setExpeditions}}>
      <themeColor.Provider value={{mode: mode, setMode: setMode}}>
      <isLoggedIn.Provider value={{loginStatus: loginStatus, setLoginStatus: setLoginStatus}}>
      <userData.Provider value={{user: user, setUser: setUser}}>
      <djUserData.Provider value={{djUser: djUser, setDjUser: setDjUser}}>
      <Router>
      <Header/>
        <Routes>
        {loginStatus === true ? <Route path="/" element={<MyTrip/>}>
          </Route> : <Route path="/" element={<Home/>}>
            </Route>}
          <Route path="/expeditions" element={<Expeditions/>}>
          </Route>
          <Route path="/expeditions/:id" element={<Expedition/>}>
          </Route>
          <Route path="*" element={<Navigate to="/" />}>
            </Route>
      </Routes>
      </Router>
      </djUserData.Provider>
      </userData.Provider>
      </isLoggedIn.Provider>
      </themeColor.Provider>
      </japanExpeditionsData.Provider>
    </div>
  
  );
}

export default App;
