import { useContext } from "react";
import { IoSunny, IoMoon } from "react-icons/io5";
import { isLoggedIn, themeColor, userData } from "../../Contexts";
import { setDarkModePref } from "../../firestoreFunctions";
import "./ColorThemeSwitch.css";
const ColorThemeSwitch = () => {
    const {mode, setMode} = useContext(themeColor);
    const {user} = useContext(userData);
    const {loginStatus} = useContext(isLoggedIn);

    const switchMode = () => {
        if(mode === 'light'){
            setMode('dark');
            if(loginStatus){
            setDarkModePref(user.googleId!, true);
            }
        }
        else{
            setMode('light')
            if(loginStatus){
            setDarkModePref(user.googleId!, false);
            }
        }
    }
    
    return(
        <button className={`switch ${mode === 'light' ? 'switch-dark' : ''}`} onClick={switchMode}>{mode === 'dark' ? <IoSunny/> : <IoMoon/>}</button>
    )
}
export default ColorThemeSwitch;
